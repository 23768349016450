import { Typewriter } from "react-simple-typewriter";
import profilePic from "../../assets/ProfilePic.jpg";

export default function Bio() {
  return (
    <section className="px-40 py-20 font-roboto mt-20" id="home">
      <div className="flex flex-row">
        <div className="w-2/3">
          {/* Biography - Name */}
          <div className="text-2xl font-black">
            <p>Heyyyy, I'm Darren Lim. &#128075;</p>
          </div>

          {/* Biography - "I'm" Introduction */}
          <div className="mt-2 text-xl">
            I'm a&nbsp;
            <span className="text-green-400 font-bold">
              <Typewriter
                words={[
                  "Software Engineer",
                  "Frontend Developer",
                  "Backend Developer",
                ]}
                cursor
                cursorStyle="_"
                loop={false}
              />
            </span>
          </div>

          {/* Biography - Introduction */}
          <div className="mt-2 font-extralight">
            <p>
              &#x1F680; An undergraduate majoring in Software Engineering, I am
              on a journey to turn my curiousity and love for technology into
              innovative solutions. Always on the lookout for the latest in the
              tech world, I thrive on exploring new technologies and embracing
              the ever-evolving landscape of software development. Let's connect
              and explore the exciting possibilities at the intersection of code
              and creativity!
            </p>
          </div>

          {/* Download Resume */}
          <div className="mt-4">
            <a
              className="border rounded-xl border-green-400 bg-green-400 px-4 py-2 text-black hover:cursor-pointer"
              href="https://1drv.ms/b/s!AqH-RXFlrv_AgYFgBR6NatJj7XPoEw?e=YZ2mfX"
            >
              Resume
            </a>
          </div>
        </div>
        <div className="ml-3 w-1/3">
          <img
            src={profilePic}
            alt="Profile Pic"
            className="bg-cover rounded-lg"
          />
        </div>
      </div>
    </section>
  );
}
