export default function SectionHeader(props) {
  return (
    <div className="flex justify-between items-center text-3xl font-bold">
      <div className="bg-slate-200 h-px flex-grow rounded-sm"></div>
      <header className="px-3 text-5xl">
        {props.header}<span className="text-green-400">.</span>
      </header>
      <div className="bg-slate-200 h-px flex-grow rounded-sm"></div>
    </div>
  );
}
