import { Icon } from "@iconify-icon/react";
import SectionHeader from "../template/SectionHeader";

export default function Skills() {
  const skillsSet = [
    {
      name: "Frontend",
      skills: [
        {
          skillName: "ReactJS",
          icon: "bxl-react",
        },
        {
          skillName: "HTML 5",
          icon: "bxl-html5",
        },
        {
          skillName: "CSS",
          icon: "bxl-css3",
        },
        {
          skillName: "Tailwind CSS",
          icon: "bxl-tailwind-css",
        },
      ],
    },
    {
      name: "Backend",
      skills: [
        {
          skillName: "Java",
          icon: "bxl-java",
        },
        {
          skillName: "Spring Boot",
          icon: "bxl-spring-boot",
        },
        {
          skillName: "Python",
          icon: "bxl-python",
        },
        {
          skillName: "Django",
          icon: "bxl-django",
        },
        {
          skillName: "Flask",
          icon: "bxl-flask",
        },
        {
          skillName: "PostgreSQL",
          icon: "bxl-postgresql",
        },
        {
          skillName: "MongoDB",
          icon: "bxl-mongodb",
        },
        {
          skillName: "Node.js",
          icon: "bxl-nodejs",
        },
      ],
    },
    {
      name: "DevOps",
      skills: [
        {
          skillName: "Git",
          icon: "bxl-git",
        },
        {
          skillName: "Docker",
          icon: "bxl-docker",
        },
        {
          skillName: "Jenkins",
          icon: "simple-icons:jenkins",
        },
      ],
    },
    {
      name: "Cloud",
      skills: [
        {
          skillName: "AWS",
          icon: "bxl-aws",
        },
      ],
    },
  ];

  return (
    <section className="py-20" id="skills">
      <SectionHeader header="Skills" />

      <div className="flex justify-center">
        <div className="grid grid-cols-2 gap-12 mt-10 w-fit">
          {skillsSet &&
            skillsSet.map((category) => (
              <div
                key={category.name}
                className="bg-neutral-700 flex flex-col border rounded-lg"
              >
                <span className="flex justify-center font-black text-2xl p-4">
                  {category.name}
                </span>
                <ul className="grid grid-cols-2">
                  {category.skills.map((skill) => (
                    <li
                      className="flex items-center px-10 py-5"
                      key={skill.skillName}
                    >
                      {/* <span className={`${skill.icon} size-6`}></span> */}
                      <span className="size-6">
                        <Icon icon={`${skill.icon}`} width="24" height="24" />
                      </span>
                      <span className="px-1">{skill.skillName}</span>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
}
