export const JobData = [
  {
    title: "Software Engineer Intern @ DBS Bank Ltd",
    year: "May'22 ~ Aug'22, Jan'23 - Dec'23",
    description: [
      "Developed a monitoring dashboard with SMS/Email alert system using ReactJS and Java Spring Boot to continuously monitor UAT servers that significantly improved the response time for server restoration in case of any outages",
      "Onboarded internal applications using commercial Identity Access Management product to provide Authorization-as-a-service that increases the security level of the application",
      "Brainstormed to improve the process from onboarding to implementation by removing overheads and increasing efficiency, which targeted to improve the overall process by 80%",
      "Provided support to resolve implementation or MFA error that user faced, receiving a 95% 5-star satisfaction rating from end-user",
    ],
  },
  {
    title: "Software Engineer Intern @ Venture Corporation Limited",
    year: "Jan'18 ~ Jul'18",
    description: [
      "Researched the communication between hardware and software",
      "Designed overview dashboard using Advantech and TCL Scripting",
      "Developed anti-static wrist strap alarm using C#",
      "Analyzed in output trend for Surface-Mount Technology (SMT) Line",
    ],
  },
  // Add more events as needed
];

export const EducationData = [
  {
    school: "Singapore Institute of Technology",
    major: "BEng (Hons) in ICT with a Major in Software Engineering",
    year: "Sept'20 ~ Dec'23",
  },
  {
    school: "Temasek Polytechnic",
    major: "Dip in Infocomm & Network Engineering",
    year: "Apr'15 ~ Apr'18",
  },
  {
    school: "Institute of Technical Education",
    major: "High Nitec in Information Technology",
    year: "Apr'13 ~ Apr'15",
  },
];
