import { useRef } from "react";
import emailjs from "@emailjs/browser";
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from "react-toastify";
import SectionHeader from "../template/SectionHeader";

export default function Contact() {
  const form = useRef();
  const captchaRef = useRef(null);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE,
        process.env.REACT_APP_EMAILJS_TEMPLATE,
        form.current,
        {
          publicKey: process.env.REACT_APP_EMAILJS_PUBLIC_KEY,
        }
      )
      .then(
        () => {
          toast.success(
            "Email sent successfully. Thank you for the contact, I will contact you at the soonest!"
          );
          console.log("SUCCESS!");
        },
        (error) => {
          toast.error(
            "Email failed to sent.\r\nPlease contact admin@darrennnnnlim.com to report the issue."
          );
          console.log("FAILED...", error.text);
        }
      );
  };
  return (
    <section className="py-20" id="contact">
      <SectionHeader header="Contact" />

      <div className="flex justify-center">
        <div className="mt-10 border rounded-lg w-fit p-10 bg-neutral-700">
          <form ref={form} onSubmit={sendEmail}>
            <div className="flex flex-col text-neutral-950 space-y-10">
              <input
                className="rounded-lg w-full p-3"
                type="text"
                name="user_name"
                placeholder="Name"
                required
              />
              <input
                className="rounded-lg w-full p-3"
                type="email"
                name="user_email"
                placeholder="Email"
                required
              />
              <textarea
                className="rounded-lg w-full p-3"
                name="message"
                placeholder="Message"
                required
              />
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                ref={captchaRef}
              />
              <div className="flex justify-center">
                <input
                  className="border rounded-xl border-green-400 bg-green-400 px-4 py-2 text-black hover:cursor-pointer"
                  type="submit"
                  value="Send"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}
