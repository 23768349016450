import { Icon } from "@iconify-icon/react";
import { SocialData } from "./SocialData";

export default function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="flex flex-col justify-center items-center mt-2 p-4 w-full">
      <div className="flex justify-center space-x-3 m-2">
        {SocialData.map((social) => (
          <a href={social.url}>
            <Icon icon={social.icon} width="24" height="24" />
          </a>
        ))}
      </div>
      <div>
        <p>&copy; {currentYear} Darren Lim Qin Yi </p>
      </div>
    </footer>
  );
}
