export const SocialData = [
  {
    name: "Linkedin",
    icon: "bxl:linkedin",
    url: "https://www.linkedin.com/in/darren-lim-qin-yi/",
  },
  {
    name: "GitHub",
    icon: "bxl:github",
    url: "https://github.com/darrennnnnlim",
  },
  {
    name: "Telegram",
    icon: "bxl:telegram",
    url: "https://t.me/darrennnnnlim",
  },
  {
    name: "Email",
    icon: "bxl:gmail",
    url: "mailto:admin@darrennnnnlim.com",
  },
];
