import "./App.css";
import Header from "./components/header/Header";
import Bio from "./components/bio/Bio";
import Skills from "./components/skills/Skills";
import Footer from "./components/footer/Footer";
import Experience from "./components/experience/Experience";
import Contact from "./components/contact/Contact";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Projects from "./components/projects/Projects";

function App() {
  return (
    <div className="bg-neutral-800 text-slate-200 h-full w-full">
      <ToastContainer theme="dark" />
      <Header />
      <div className="flex flex-col">
        <div className="mx-40">
          <Bio />
          <Skills />
          <Experience />
          <Projects />
          <Contact />
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default App;
