import React, { useState, useEffect } from "react";

export default function Header() {
  const navLink = [
    {
      name: "Home",
      link: "#home",
    },
    {
      name: "Skills",
      link: "#skills",
    },
    {
      name: "Experience",
      link: "#experience",
    },
    {
      name: "Contact",
      link: "#contact",
    },
  ];

  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [topOfScreen, setTopOfScreen] = useState(true);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  useEffect(() => {
    const scrollHandler = () => {
      window.scrollY > 40 ? setTopOfScreen(false) : setTopOfScreen(true);
    };
    window.addEventListener("scroll", scrollHandler);
    return () => window.removeEventListener("scroll", scrollHandler);
  }, [topOfScreen]);

  return (
    // <header className="fixed p-4 font-roboto mb-24">
    <header
      className={`fixed p-4 font-roboto w-full bg-neutral-800 ${
        !topOfScreen && `shadow-md`
      }`}
    >
      <div className="flex justify-between text-slate-400 mx-40">
        {/* <div className="flex justify-between lg:mx-40 text-slate-400"> */}
        <div className="text-xl grow-0 font-herr-von-muellerhoff">
          {/* darrennnnnlim */}
          <a href="/">darrennnnnlim</a>
        </div>
        <nav className="lg:flex lg:items-center hidden">
          <ul className="flex items-center text-xl">
            {navLink.map((navItem) => (
              <li key={navItem.name} className="mx-2 hover:text-slate-200">
                <a href={navItem.link}>{navItem.name}</a>
              </li>
            ))}
          </ul>
        </nav>
        {/* Hamburger menu for small screens */}
        <div className="lg:hidden cursor-pointer" onClick={toggleMobileMenu}>
          <svg
            className="h-6 w-6 fill-current text-slate-400"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16m-7 6h7"
            ></path>
          </svg>
        </div>
      </div>
      {/* Mobile menu */}
      {isMobileMenuOpen && (
        <div className="lg:hidden">
          <ul className="flex flex-col items-center text-xl text-slate-400">
            {navLink.map((navItem) => (
              <li key={navItem.name} className="my-2 hover:text-slate-200">
                <a href={navItem.link} onClick={toggleMobileMenu}>
                  {navItem.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}
    </header>
  );
}
