import SectionHeader from "../template/SectionHeader";
import { JobData } from "./ExperienceData";

export default function Experience() {
  return (
    <section className="py-20" id="experience">
      <SectionHeader header="Experience" />

      <div className="container mx-auto mt-10">
        <div className="flex flex-col space-y-8">
          {JobData.map((experience, index) => (
            <div
              key={index}
              className={`flex ${
                index % 2 === 0 ? "flex-row-reverse" : "flex-row"
              } items-center`}
            >
              <div className="flex-none w-1/2">
                <div className="bg-neutral-700 p-4 rounded-lg shadow-md">
                  <h2 className="text-lg font-semibold">{experience.title}</h2>
                  <p className="text-sm">{experience.year}</p>
                  <ul className="mt-2 list-inside list-disc">
                    {experience.description.map((item) => (
                      <li>{item}</li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="flex-grow relative">
                <div
                  className={`absolute ${
                    index % 2 === 0 ? "right-0" : "left-0"
                  } top-0 w-0.5 h-full bg-orange-900`}
                ></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
